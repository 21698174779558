// so expose-loader can expose, layout/devise wants global jQ
import 'jquery'
import Rails from '@rails/ujs'

// we use it for login as okta
import 'bootstrap/js/dist/modal'
// for flash notifications
import 'bootstrap/js/dist/alert'

Rails.start()
